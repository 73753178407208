import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Injectable({ providedIn: "root" })
export class NotificationService {

  constructor(
    public snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {}

  showSuccess(message: string, duration: number = 3000, position: MatSnackBarVerticalPosition = "top"): void {
    this.openSnackbar(message, "green-snackbar", duration, "", position);
  }

  showError(message: string, duration: number = 3000, position: MatSnackBarVerticalPosition = "top"): void {
    this.openSnackbar(message, "error", duration, "x", position);
  }

  private openSnackbar(
    message: string,
    panelClass: string = "",
    duration: number = 3000,
    action: string = "",
    verticalPosition: MatSnackBarVerticalPosition = "top"
  ) {
    this.ngZone.run(() => {
      this.snackBar.open(message, action, {
        panelClass: [panelClass],
        duration,
        verticalPosition,
        horizontalPosition: 'center'
      });
    });
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }

  logError(error): void {
    console.error(error);
  }
}
